<template>
  <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" class="px-7">
    <v-row>
      <v-col cols="12">
        <v-img :height="height" :src="item.image" @click="goToDetail(item.id)">
          <template v-slot:placeholder>
            <v-sheet height="350px">
              <v-skeleton-loader
                class="mx-auto img-loader"
                loading
                type="image"
                height="350px"
              ></v-skeleton-loader>
            </v-sheet>
          </template>
        </v-img>
        <v-card flat color="white" class="pb-2 text-left">
          <v-card-text class="px-md-0 mx-md-0">
            <v-row no-gutters>
              <v-col cols="12">
                <h2
                  class="pb-0 mb-0 body-font primary--text text-md-h5 font-weight-medium"
                >
                  {{ item.name }}
                </h2>
                <h3 class="pt-5 pb-0 mb-0 body-font font-weight-regular">
                  {{ item.shortDescription }}
                </h3>
              </v-col>
              <v-col
                cols="12"
                align="right"
                class="d-flex justify-end align-center pt-4"
                @click="goToDetail(item.id)"
              >
                <h4 class="font-weight-medium body-font pr-md-3">
                  {{ $t("lblContinuarLeyendo") }}
                </h4>
                <v-btn
                  icon
                  outlined
                  small
                  color="primary"
                  @click="goToDetail(item.id)"
                >
                  <v-icon small>mdi-arrow-right</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
  <v-col v-else cols="12" md="10" lg="8" class="offset-lg-2 offset-md-1">
    <v-row
      class="relative_container"
      data-aos="fade-down"
      data-aos-delay="500"
      data-aos-duration="1500"
    >
      <v-col cols="12" md="6">
        <v-img
          :height="height"
          :src="item.principalImg"
          @click="goToDetail(item.id)"
        >
          <template v-slot:placeholder>
            <v-sheet height="350px">
              <v-skeleton-loader
                class="mx-auto img-loader"
                loading
                type="image"
                height="350px"
              ></v-skeleton-loader>
            </v-sheet>
          </template>
        </v-img>
      </v-col>
      <v-col cols="12" md="6"> </v-col>
      <div class="description_div">
        <v-card
          flat
          color="white"
          max-width="550"
          class="mt-10 px-10 pt-10 pb-2 text-left"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12">
                <h2
                  class="pb-0 mb-0 body-font primary--text text-md-h5 font-weight-medium"
                  style="min-height: 52px"
                >
                  {{ language === "es" ? item.name : item.nameEng }}
                </h2>
                <h3
                  class="short-description pt-5 pb-0 mb-0 body-font font-weight-regular"
                >
                  {{
                    language === "es"
                      ? item.shortDescription
                      : item.shortDescriptionEng
                  }}
                </h3>
              </v-col>
              <v-col
                cols="12"
                align="right"
                class="d-flex justify-end align-center pt-10"
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="1500"
                data-aos-anchor-placement="bottom-bottom"
                @click="goToDetail(item.id)"
              >
                <h4 class="font-weight-medium body-font pr-md-3">
                  {{ $t("lblContinuarLeyendo") }}
                </h4>
                <v-btn
                  icon
                  outlined
                  small
                  color="primary"
                  @click="goToDetail(item.id)"
                >
                  <v-icon small>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "PrincipalPreview",
  props: {
    item: Object,
    noScrolling: Boolean,
  },
  data: function () {
    return {
      height: 350,
      language: localStorage.getItem("language"),
    };
  },
  methods: {
    goToDetail(id) {
      this.$router.push({
        name: "DetailedView",
        params: {
          id: id,
        },
      });
    },
    dimensions() {
      if (this.$vuetify.breakpoint.md) {
        this.height = 350;
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.height = 410;
      }
    },
  },
  mounted() {
    this.dimensions();
  },
};
</script>

<style scoped>
::v-deep .v-skeleton-loader > * {
  height: 100%;
  display: flex;
  flex-direction: column;
}

::v-deep .v-skeleton-loader .v-skeleton-loader__bone {
  flex-grow: 1;
}
</style>
<style scoped src="@/assets/css/home.css"></style>
<style scoped src="@/assets/css/proyects.css"></style>
