import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#008cdc",
        secondary: "#ffffff",
        secondary_original: "#ffffff",
        custom_blue: "#152b7f",
        custom_grey: "#E7EEF3",
        support: colors.grey.darken2,
      },
    },
  },
});
