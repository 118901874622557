<template>
  <v-app>
    <custom-app-bar
      :sidebar="sidebar"
      :items="items"
      :item-icon="itemIcon"
      @sidebar-event="handleSideBar"
    />
    <v-navigation-drawer
      v-model="sidebar"
      app
      dark
      color="primary"
      style="width: 100%"
      src="@/assets/images/texture_circle.png"
    >
      <v-layout column justify-center justify-space-evenly fill-height>
        <div class="">
          <v-list-item>
            <v-list-item-title class="d-flex justify-center align-center">
              <v-img
                src="/img/logo-dedca.png"
                alt="alt"
                class=""
                max-width="150"
              />
            </v-list-item-title>
          </v-list-item>
        </div>
        <v-list dense nav expand class="mt-10 pt-10 mx-16">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="handleNavigation(item)"
          >
            <v-list-item-avatar>
              <v-icon color="white"> mdi-menu </v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="py-3">
              <h3>{{ item.title }}</h3>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
      <template v-slot:append>
        <div class="pb-16 mb-16 d-flex align-center justify-center">
          <v-btn
            @click="handleSideBar(false)"
            icon
            outlined
            elevation="0"
            color="secondary"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main class="pt-0 bg-home">
      <v-row id="heading-1" align="end" class="f-h" no-gutters>
        <v-col cols="12" md="8" class="">
          <div class="pt-md-15 mt-16 pt-16">
            <div
              class="bg-hero"
              :style="
                $vuetify.breakpoint.lgAndUp
                  ? 'max-width: 85%'
                  : $vuetify.breakpoint.md
                  ? 'max-width: 90%'
                  : 'max-width: 80%'
              "
            >
              <h3
                class="py-4 white--text title-font text-lg-h3 text-md-h4 pl-10 pl-md-15 pr-4"
                data-aos="fade-down"
                data-aos-duration="1500"
              >
                {{ $t("lblHomeDescription") }}
              </h3>
            </div>
            <div class="pl-10 pl-md-15 pt-md-10 mt-16 mt-md-2 pt-16">
              <v-btn
                color="custom_blue"
                :x-large="$vuetify.breakpoint.mdAndUp"
                :large="$vuetify.breakpoint.xs"
                dark
                data-aos="fade-up"
                data-aos-duration="1500"
                @click="$vuetify.goTo('#heading-6')"
              >
                {{ $t("btnQuieroServicios") }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <!-- <v-col cols="12" align="center" class="d-flex flex-column">
          <v-img
            :src="'/img/iconos/icon_dente.svg'"
            max-height="30"
            contain
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          ></v-img>
          <small
            class="white--text pt-3"
            data-aos="fade-up"
            data-aos-delay="700"
            data-aos-duration="2000"
            data-aos-anchor-placement="center-bottom"
          >
            {{ $t("lblDesliza") }}
          </small>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row id="heading-2" class="" no-gutters>
            <v-col cols="12" align="center" class="pt-15 px-5 px-md-1">
              <h1
                class="pt-15 white--text title-font text-h5 text-lg-h4 text-md-h4"
                data-aos="fade-down"
                data-aos-delay="300"
                data-aos-duration="1500"
              >
                {{ $t("lblPrincipalQueHacemos") }}
              </h1>
              <h5
                class="white--text body-font pt-8 pb-16 text-h6 text-md-h6"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1500"
              >
                {{ $t("lblSecondaryQueHacemos") }}
              </h5>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12" md="10" lg="8" class="offset-md-1 offset-lg-2">
              <v-row>
                <v-col
                  cols="6"
                  align="center"
                  class="py-10 px-md-0"
                  v-for="(service, i) in services"
                  :key="service.name"
                >
                  <v-card
                    @click="switchService(i)"
                    link
                    color="transparent"
                    elevation="0"
                  >
                    <individual-service :item="service" />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" align="center" class="py-10 px-15 px-md-0">
          <v-card
            elevation="0"
            max-width="444"
            class="mx-auto"
            color="transparent"
          >
            <v-img
              :src="currentService.urlOrange"
              max-width="100"
              contain
            ></v-img>
            <div class="pt-3">
              <h3 class="blue--text title-font text-h3">
                {{ currentService.name }}
              </h3>
              <br />
              <h3 class="orange--text">
                {{ currentService.description }}
              </h3>
              <br />
              <h4 class="secondary--text">
                {{ currentService.longDescription }}
              </h4>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <preview-proyects />
      <v-row id="heading-5" class="pb-15 secondary">
        <v-col cols="12" align="center" class="pt-15 px-5 px-md-0">
          <v-img
            src="/img/homepage_studio2.jpg"
            max-width="400"
            contain
          ></v-img>

          <h1
            class="pt-15 primary--text title-font text-lg-h3 text-md-h3 mr-sm-15 ml-sm-15"
            data-aos="fade-down"
            data-aos-delay="1200"
            data-aos-duration="1500"
          >
            {{ $t("lblQuienesSomosTitle") }}
          </h1>
          <h4
            class="font-weight-medium support--text body-font pt-8 pb-16 text-lg-h6 px-md-16"
            data-aos="fade-up"
            data-aos-delay="1500"
            data-aos-duration="1500"
          >
            {{ $t("lblQuienesSomosSubTitle") }}
          </h4>
        </v-col>
      </v-row>
      <contact-us />
    </v-main>
    <custom-footer />
    <!-- <div class="social_container">
      <v-speed-dial
        v-model="floatingButton"
        bottom
        right
        direction="top"
        transition="slide-y-reverse-transition"
        :open-on-hover="$vuetify.breakpoint.mdAndUp"
      >
        <template v-slot:activator>
          <v-btn v-model="floatingButton" color="blue darken-2" dark fab>
            <v-icon v-if="floatingButton"> mdi-close </v-icon>
            <v-img
              v-else
              class="trasparent"
              contain
              max-height="35"
              max-width="35"
              :src="'/img/iconos/chat.png'"
            ></v-img>
          </v-btn>
        </template>
        <v-btn
          fab
          small
          color="transparent"
          class=""
          href="https://wa.me/31144488?text=Me%20gustaría%20contratar%20sus%20servicios."
          target="_blank"
        >
          <v-img
            class="trasparent"
            contain
            max-height="47"
            max-width="47"
            :src="'/img/iconos/whatsapp.png'"
          ></v-img>
        </v-btn>
      </v-speed-dial>
    </div> -->
  </v-app>
</template>
<script>
import CustomFooter from "@/components/Home/CustomFooter";
import CustomAppBar from "@/components/Home/CustomAppBar";
import IndividualService from "@/components/Home/IndividualService";
import PreviewProyects from "@/components/Home/sections/PreviewProyects";
import ContactUs from "@/components/Home/sections/ContactUs";
import vuetify from "@/plugins/vuetify";

export default {
  name: "HomeView",
  components: {
    ContactUs,
    IndividualService,
    CustomAppBar,
    CustomFooter,
    PreviewProyects,
  },
  data: function () {
    return {
      floatingButton: false,
      isMobile: false,
      sidebar: false,
      itemIcon: {
        tarjet: "#heading-1",
        outlined: true,
        toPage: false,
      },
      items: [
        {
          title: this.$t("titleQuienesSomos"),
          tarjet: "#heading-2",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: this.$t("titleProducts"),
          tarjet: "#heading-5",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: this.$t("titleContacto"),
          tarjet: "#heading-6",
          text: true,
          outlined: false,
          toPage: false,
        },
      ],
      services: [
        {
          name: this.$t("nameConfortable"),
          url: "/img/iconos/icon_dente.svg",
          urlOrange: "/img/iconos/icon_denteArancio.svg",
          description: "No provoca sensibilidad",
          longDescription:
            "¿Es doloroso? En absoluto, es totalmente seguro y delicado. Con BlancOne CLICK los casos de hipersensibilidad son sumamente raros.",
          width: {
            md: "50%",
            lg: "50%",
          },
        },
        {
          name: this.$t("nameRapido"),
          url: "/img/iconos/icon_time.svg",
          urlOrange: "/img/iconos/icon_OrangeTime.svg",
          description: "Solo 10 minutos",
          longDescription:
            "¿Cuánto tiempo se necesita? Solo 10 minutos. Es el tratamiento ideal para combinarlo con la sesión de higiene oral. Con tan solo 10 minutos más, darás nueva vida y brillo a tu sonrisa.",
          width: {
            md: "60%",
            lg: "50%",
          },
        },
        {
          name: this.$t("nameEconomico"),
          url: "/img/iconos/icon_savemoney.svg",
          urlOrange: "/img/iconos/icon_pigArancio.svg",
          description: "Al alcance de todos",
          longDescription:
            "¿Es caro? ¡En absoluto! Como una cena para dos, una sesión de masaje... ¡BlancOne CLICK está al alcance de todos!",
          width: {
            md: "80%",
            lg: "60%",
          },
        },
        {
          name: this.$t("nameWow"),
          url: "/img/iconos/icon_woweffect.svg",
          urlOrange: "/img/iconos/icon_wowArancio.svg",
          description: "Nuestra garantía",
          longDescription:
            "¿Obtendré resultados inmediatos? Los resultados serán inmediatamente visibles y podrás mejorarlos aún más incorporando el blanqueamiento en tu rutina periódica para el bienestar de la sonrisa.",
          width: {
            md: "80%",
            lg: "60%",
          },
        },
      ],
      currentService: {
        name: this.$t("nameConfortable"),
        url: "/img/iconos/icon_dente.svg",
        urlOrange: "/img/iconos/icon_denteArancio.svg",
        description: "No provoca sensibilidad",
        longDescription:
          "¿Es caro? ¡En absoluto! Como una cena para dos, una sesión de masaj... ¡BlancOne CLICK está al alcance de todos!",
        width: {
          md: "50%",
          lg: "50%",
        },
      },
      currentServiceIndex: 0,
      hasModifed: false,
    };
  },
  methods: {
    vuetify() {
      return vuetify;
    },
    handleSideBar(sidebarValue) {
      this.sidebar = sidebarValue;
    },
    closeNavigation() {
      this.handleSideBar(false);
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
      }
      if (this.$vuetify.breakpoint.xs) {
        this.handleSideBar();
      }
    },
    onResize() {
      if (window.innerWidth >= 600) {
        this.sidebar = false;
      }
    },
    switchService(i) {
      this.currentServiceIndex = i;
      this.hasModifed = true;
      this.currentService = { ...this.services[i] };
    },
    scheduleCurrentProject() {
      if (this.hasModifed) {
        this.hasModifed = false;
        return;
      }
      this.currentServiceIndex =
        this.currentServiceIndex >= this.services.length
          ? 0
          : this.currentServiceIndex + 1;
      this.currentService = { ...this.services[this.currentServiceIndex] };
    },
  },
  mounted() {
    this.onResize();
    this.$store.dispatch("projects/getPublicProjects");
    this.intervalId = setInterval(this.scheduleCurrentProject, 5000);
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    clearInterval(this.intervalId);
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.bg-home {
  background-image: url("~@/assets/images/texture_circle.png"),
    linear-gradient(to right, #009bdc, #1b1464);
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

@media (max-width: 600px) {
  .bg-home {
    /* background-image: url("~@/assets/images/nautica-mobile-2.jpg"); */
    background-position: right bottom;
  }
}

.v-speed-dial {
  position: absolute;
}
.v-btn--floating {
  position: relative;
}

.bg-hero {
  /* background: rgba(204, 178, 154, 0.69); */
  border-radius: 10px;
}
</style>
