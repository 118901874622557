import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config.js";
import projects from "./modules/projects";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config,
    projects,
  },
});
