<template>
  <v-app>
    <transition
      enter-active-class="animate__animated animate__fadeIn "
      leave-active-class="animate__animated animate__fadeOut "
    >
      <router-view />
    </transition>
  </v-app>
</template>

<script>
import { Trans } from "@/plugins/translate";

export default {
  name: "App",

  data: function () {
    return {};
  },
  mounted() {
    const language = localStorage.getItem("language");
    if (!language) {
      localStorage.setItem("language", "es");
    }
    Trans.currentLocale(language || "es");
  },
};
</script>

<style>
.colForm {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
