/* eslint-disable */
import { config, CognitoIdentityCredentials, S3 } from "aws-sdk";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import awsConfig from "../config";

config.region = awsConfig.region;
config.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: awsConfig.IdentityPoolId,
});

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.UserPoolId,
  ClientId: awsConfig.ClientId,
});

function ConfirmUser({ user, code }) {
  const userData = {
    Username: user,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });
}

function Login(Username, Password) {
  const authDetails = new AuthenticationDetails({ Username, Password });
  const cognitoUser = new CognitoUser({ Username, Pool: userPool });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const logins = {};
        logins[awsConfig.CognitoIdp] = result.getIdToken().getJwtToken();
        config.credentials = new CognitoIdentityCredentials({
          IdentityPoolId: awsConfig.UserPoolId,
          Logins: logins,
        });
        resolve({
          result,
          message: "Login succesfuly",
        });
      },
      onFailure: (error) => {
        reject(error);
      },
      newPasswordRequired: (userAttributes) => {
        resolve({ userAttributes, cognitoUser, newPassword: true });
      },
    });
  });
}

function ResendCode(user) {
  const userData = {
    Username: user,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.resendConfirmationCode(() => {});
}

function Logout() {
  userPool.getCurrentUser().signOut();
}

function ChangePassword(newPassword, userAttributes, cognitoUser) {
  return new Promise((resolve, reject) =>
    cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: (result) => {
        resolve({
          message: "Login succesfuly",
          result,
        });
      },
      onFailure: (error) => {
        reject(error);
      },
    })
  );
}

function RequestChangePassword(user) {
  const userData = {
    Username: user,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        resolve(data);
      },
      onFailure: function (error) {
        reject(error);
      },
    });
  });
}

function ConfirmChangePassword(user, password, code) {
  const userData = {
    Username: user,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, password, {
      onSuccess() {
        resolve({ message: "Cuenta recuperada" });
      },
      onFailure(error) {
        reject(error);
      },
    });
  });
}

function CheckSession() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          reject(err);
          return;
        }
        const Logins = {};
        Logins[awsConfig.CognitoIdp] = session.getIdToken().getJwtToken();
        config.credentials = new CognitoIdentityCredentials({
          IdentityPoolId: awsConfig.IdentityPoolId,
          Logins,
        });
        if (AWS.config.credentials.needsRefresh()) {
          cognitoUser.refreshSession(
            session.getRefreshToken(),
            (err, session) => {
              if (err) {
                reject(err);
                return false;
              } else {
                resolve({
                  result: session,
                });
              }
            }
          );
        } else {
          resolve({
            result: session,
          });
        }
      });
    } else{
      reject();
    }
  });
}

function GetToken() {
  const user = userPool.getCurrentUser();
  const key = `CognitoIdentityServiceProvider.${awsConfig.ClientId}`;
  const userName = user.storage[`${key}.LastAuthUser`];
  return user.storage[`${key}.${userName}.idToken`];
}

function GetCurrentUser() {
  return userPool.getCurrentUser();
}

async function uploadFile(filename, props, outStream, bucket) {
  const s3 = new S3({
    apiVersion: "2006-03-01",
    params: { Bucket: bucket },
  });
  const params = {
    Bucket: bucket,
    Key: `${props}/${filename}`,
    Body: outStream,
  };
  try {
    await s3.upload(params).promise();
    return `${process.env.VUE_APP_STATICS}${params.Key}`
  } catch (error) {
    // console.error(error);
    throw false;
  }
}

export {
  Login,
  Logout,
  ChangePassword,
  ConfirmUser,
  ResendCode,
  RequestChangePassword,
  ConfirmChangePassword,
  CheckSession,
  GetToken,
  GetCurrentUser,
  uploadFile,
};
