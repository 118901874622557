// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/texture_circle.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bg-home[data-v-28131969]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),linear-gradient(90deg,#009bdc,#1b1464);background-size:cover;background-repeat:no-repeat;background-position:100%}@media (max-width:600px){.bg-home[data-v-28131969]{background-position:100% 100%}}.v-speed-dial[data-v-28131969]{position:absolute}.v-btn--floating[data-v-28131969]{position:relative}.bg-hero[data-v-28131969]{border-radius:10px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
