<template>
  <div data-aos="fade" data-aos-delay="500" data-aos-duration="1500">
    <v-img :src="item.url" max-width="100" contain></v-img>
    <div class="pt-3">
      <small class="secondary--text title-font text-h6 text-no-wrap">
        {{ item.name }}
      </small>
      <br />
      <small class="secondary--text">
        {{ item.description }}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndividualService",
  props: {
    item: Object,
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped></style>
