import { projectAPI } from "@/api";
import { messages } from "@/utils";

const state = () => ({
  projects: [],
  publicProjects: [
    {
      id: "1",
      name: "BlancOne® Click",
      nameEng: "BlancOne® Click",
      description:
        "¡Bastan solo 10 minutos y un desembolso al alcance de todos para obtener un resultado sorprendente y brindar a tus dientes un aspecto más luminoso!",
      descriptionEng: "Producto 2",
      shortDescription:
        "¡Bastan solo 10 minutos y un desembolso al alcance de todos para obtener un resultado sorprendente y brindar a tus dientes un aspecto más luminoso!",
      shortDescriptionEng: "Producto 2",
      published: true,
      date: "Test",
      principalImg: "/img/one.jpg",
      image: "/img/one.jpg",
      images: [
        "/img/producto1-1.png",
        null,
        "/img/producto1-2.png",
        // "/img/producto1-3.jpeg",
        // "/img/producto1-4.png",
      ],
      updatedAt: "Test",
      createdAt: "Test",
    },
    {
      id: "2",
      name: "BlancOne® ULTRA",
      nameEng: "BlancOne® ULTRA",
      description:
        "Tratamiento de blanqueamiento profesional ideal para hacer frente a las situaciones más difíciles como los dientes muy oscuros o con discromías causadas por terapias farmacológicas en la infancia.",
      descriptionEng: "Producto 1",
      shortDescription:
        "Tratamiento de blanqueamiento profesional ideal para hacer frente a las situaciones más difíciles como los dientes muy oscuros o con discromías causadas por terapias farmacológicas en la infancia.",
      shortDescriptionEng: "Producto 1",
      published: true,
      date: "Test",
      principalImg: "/img/ultra.jpeg",
      image: "/img/ultra.jpeg",
      images: ["/img/producto2-1.png", null, "/img/producto2-2.jpeg"],
      updatedAt: "Test",
      createdAt: "Test",
    },
    {
      id: "3",
      name: "Blancone® Arcus",
      nameEng: "Producto 3",
      description:
        "Lámpara fotoactivadora ARCUS en pocos minutos, sin elevar la temperatura del diente y sin deshidratar el esmalte, brindará a sus pacientes dientes más blancos de modo profesional, confiable y seguro.",
      shortDescription:
        "Lámpara fotoactivadora ARCUS en pocos minutos, sin elevar la temperatura del diente y sin deshidratar el esmalte, brindará a sus pacientes dientes más blancos de modo profesional, confiable y seguro.",
      descriptionEng: "Detalle corto sobre el producto 3",
      shortDescriptionEng: "Producto 3",
      published: true,
      date: "Test",
      principalImg: "/img/arcus.jpeg",
      image: "/img/arcus.jpeg",
      images: ["/img/producto3-1.jpeg", null, "/img/producto3-2.jpeg"],
      updatedAt: "Test",
      createdAt: "Test",
    },
  ],
  project: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  async getProject({ commit }, { id }) {
    commit("setLoading", true);
    const project = await projectAPI.getProject(id);
    if (project.error) {
      commit("setLoading", false);
      messages.error(project.message);
      return;
    }
    commit("setProject", project);
  },
  async getProjects({ commit }) {
    commit("setLoading", true);
    const projects = await projectAPI.getProjects();
    if (projects.error) {
      commit("setLoading", false);
      messages.error(projects.message);
      return;
    }
    commit("setProjects", projects);
  },
  async createProject({ commit }, { data }) {
    commit("setLoadingSave", true);
    const project = await projectAPI.createProject(data);
    if (project.error) {
      commit("setLoadingSave", false);
      messages.error(project.message);
      return;
    }
    messages.success("El proyecto ha sido creado");
    commit("createProject", { ...data, id: project.id });
  },
  async updateProject({ commit }, { data }) {
    commit("setLoadingSave", true);
    const project = await projectAPI.updateProject(data);
    if (project.error) {
      commit("setLoadingSave", false);
      messages.error(project.message);
      return;
    }
    messages.success("El proyecto ha sido actualizado");
    commit("updateProject", data);
  },
  async deleteProject({ commit }, { id }) {
    commit("setLoadingSave", true);
    const project = await projectAPI.deleteProject(id);
    if (project.error) {
      commit("setLoadingSave", false);
      messages.error(project.message);
      return;
    }
    messages.success("Se ha eliminado el proyecto");
    commit("deleteProject", id);
  },
  async getPublicProjects({ commit }) {
    commit("setLoading", true);
    commit("setLoading", false);
    // const projects = await projectAPI.getPublicProjects();
    // if (projects.error) {
    //   commit("setLoading", false);
    //   messages.error(projects.message);
    //   return;
    // }
    // commit(
    //   "setPublicProjects",
    //   projects.slice().filter((item) => item.published === true)
    // );
  },
  async setImages({ commit }, { data }) {
    commit("setLoadingSave", true);
    const project = await projectAPI.updateProject(data);
    if (project.error) {
      commit("setLoadingSave", false);
      messages.error(project.message);
      return;
    }
    messages.success("Las imágenes han sido agregadas");
    commit("updateProject", data);
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setProjects(state, projects) {
    state.loading = false;
    state.projects = projects;
  },
  setPublicProjects(state, projects) {
    state.loading = false;
    state.publicProjects = projects;
  },
  setProject(state, project) {
    state.loading = false;
    state.project = project;
  },
  createProject(state, project) {
    state.loadingSave = false;
    state.projects = [
      {
        ...project,
      },
      ...state.projects,
    ];
  },
  updateProject(state, project) {
    const index = state.projects.findIndex((item) => item.id === project.id);
    const items = [...state.projects];
    items[index] = {
      ...items[index],
      ...project,
    };
    state.loadingSave = false;
    state.projects = items;
  },
  deleteProject(state, id) {
    const index = state.projects.findIndex((item) => item.id === id);
    const items = [...state.projects];
    items.splice(index, 1);
    state.loadingSave = false;
    state.projects = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
