import i18n from "../i18n";

const Trans = {
  defaultLocale() {
    return process.env.VUE_APP_I18N_LOCALE;
  },
  supportedLocales() {
    return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
  },
  currentLocale(locale) {
    i18n.locale = locale;
  },
};
export { Trans };
