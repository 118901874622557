import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import MasonryWall from "@yeger/vue2-masonry-wall";
import CountryFlag from "vue-country-flag";
import { Buffer } from "buffer";
window.Buffer = Buffer;
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
Vue.component("country-flag", CountryFlag);
Vue.config.productionTip = false;
Vue.use(VueToast);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITE_KEY });
Vue.use(MasonryWall);
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  mounted() {
    AOS.init({
      once: true,
    });
  },
}).$mount("#app");
