<template>
  <v-row id="heading-6" class="">
    <v-col cols="12" sm="2" class="secondary"></v-col>
    <v-col cols="12" sm="8" class="secondary px-10 px-lg-16">
      <h1 class="pt-15 primary--text title-font text-lg-h4 text-md-h4 pr-md-16">
        {{ $t("titleContactanos") }}
      </h1>
      <h4
        class="support--text body-font pt-8 pb-16 font-weight-medium pr-md-10"
      >
        {{ $t("subtitleContactanos") }}
      </h4>
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              filled
              color="primary"
              :placeholder="$t('lblNombre')"
              dense
              v-model="item.name"
              :rules="noEmptyRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              filled
              color="primary"
              :placeholder="$t('lblTelefono')"
              dense
              v-model="item.phoneNumber"
              :rules="noEmptyRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              filled
              color="primary"
              :placeholder="$t('lblCorreo')"
              dense
              v-model="item.email"
              :rules="emailRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              filled
              color="primary"
              :placeholder="$t('lblMotivo')"
              dense
              v-model="item.reason"
              :rules="noEmptyRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              filled
              dense
              rows="3"
              no-resize
              color="primary"
              :placeholder="$t('lblComentario')"
              v-model="item.comment"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="mb-16 font-weight-medium text-body-1 body-font"
          >
            <!-- <div class="mb-3">
              <small
                class="font-weight-medium text-body-1 body-font support--text"
              >
                +(502) 1234 5678
              </small>
            </div> -->
            <div class="mb-3">
              <small
                class="font-weight-medium text-body-1 body-font support--text"
              >
                informacion@dedcalatam.com
              </small>
            </div>
            <div>
              <small
                class="font-weight-medium text-body-1 body-font support--text"
              >
                {{ $t("lblDireccion") }}
              </small>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="mb-16 d-flex justify-end">
            <v-btn
              large
              :block="$vuetify.breakpoint.xs"
              color="primary"
              elevation="0"
              @click="sendMessageContact"
              :loading="loading"
            >
              {{ $t("btnEnviar") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col cols="12" sm="2" class="secondary"></v-col>
  </v-row>
</template>

<script>
import { contactanos } from "@/api";
import { messages } from "@/utils";
export default {
  name: "ContactUs",
  data: function () {
    return {
      loading: false,
      item: {
        name: "",
        email: "",
        reason: "",
        comment: "",
        phoneNumber: "",
      },
      emailRules: [
        (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          this.$t("lblValidEmail"),
      ],
      noEmptyRules: [(value) => !!value || this.$t("lblCompletaDato")],
    };
  },
  methods: {
    recaptcha() {
      return new Promise((resolve) => {
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("contactanos").then((token) => {
            resolve(token);
          });
        });
      });
    },
    async sendMessageContact() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      const token = await this.recaptcha();
      const data = {
        ...this.item,
        token,
      };
      const { error } = await contactanos(data);
      this.loading = false;
      if (error) {
        messages.error(this.$t("msgContactError"));
      } else {
        messages.success(this.$t("msgContactSuccess"));
        this.clearForm();
      }
    },
    clearForm() {
      this.item = {
        name: "",
        email: "",
        reason: "",
        comment: "",
        phoneNumber: "",
      };
      this.$refs.form.reset();
    },
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.cover-contact-us {
  min-height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-image: url("~@/assets/images/image_people.jpg") !important;
}
</style>
