<template>
  <router-view />
</template>

<script>
export default {
  name: "ProyectosView",
};
</script>

<style scoped></style>
