const state = () => ({
  name: "",
  loading: false,
});

// getters
const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
