import axios from "axios";
import { GetToken } from "../auth";
const URL = `${process.env.VUE_APP_URL}/projects`;

async function getProjects() {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.get(URL, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function getPublicProjects() {
  return [
    {
      id: "1",
      name: "BlancOne® Click",
      nameEng: "BlancOne® Click",
      description:
        "¡Bastan solo 10 minutos y un desembolso al alcance de todos para obtener un resultado sorprendente y brindar a tus dientes un aspecto más luminoso!",
      descriptionEng: "Producto 2",
      shortDescription:
        "¡Bastan solo 10 minutos y un desembolso al alcance de todos para obtener un resultado sorprendente y brindar a tus dientes un aspecto más luminoso!",
      shortDescriptionEng: "Producto 2",
      published: true,
      date: "Test",
      principalImg: "/img/one.jpg",
      image: "/img/one.jpg",
      images: [
        "/img/producto1-1.png",
        null,
        "/img/producto1-2.png",
        // "/img/producto1-3.jpeg",
        // "/img/producto1-4.png",
      ],
      updatedAt: "Test",
      createdAt: "Test",
    },
    {
      id: "2",
      name: "BlancOne® ULTRA",
      nameEng: "BlancOne® ULTRA",
      description:
        "Tratamiento de blanqueamiento profesional ideal para hacer frente a las situaciones más difíciles como los dientes muy oscuros o con discromías causadas por terapias farmacológicas en la infancia.",
      descriptionEng: "Producto 1",
      shortDescription:
        "Tratamiento de blanqueamiento profesional ideal para hacer frente a las situaciones más difíciles como los dientes muy oscuros o con discromías causadas por terapias farmacológicas en la infancia.",
      shortDescriptionEng: "Producto 1",
      published: true,
      date: "Test",
      principalImg: "/img/ultra.jpeg",
      image: "/img/ultra.jpeg",
      images: ["/img/producto2-1.png", null, "/img/producto2-2.jpeg"],
      updatedAt: "Test",
      createdAt: "Test",
    },
    {
      id: "3",
      name: "Blancone® Arcus",
      nameEng: "Producto 3",
      description:
        "Lámpara fotoactivadora ARCUS en pocos minutos, sin elevar la temperatura del diente y sin deshidratar el esmalte, brindará a sus pacientes dientes más blancos de modo profesional, confiable y seguro.",
      shortDescription:
        "Lámpara fotoactivadora ARCUS en pocos minutos, sin elevar la temperatura del diente y sin deshidratar el esmalte, brindará a sus pacientes dientes más blancos de modo profesional, confiable y seguro.",
      descriptionEng: "Detalle corto sobre el producto 3",
      shortDescriptionEng: "Producto 3",
      published: true,
      date: "Test",
      principalImg: "/img/arcus.jpeg",
      image: "/img/arcus.jpeg",
      images: ["/img/producto3-1.jpeg", null, "/img/producto3-2.jpeg"],
      updatedAt: "Test",
      createdAt: "Test",
    },
  ];
}

async function getProject(id) {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.get(`${URL}/${id}`, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function createProject(project) {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.post(`${URL}`, project, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function updateProject(project) {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.put(`${URL}`, project, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function deleteProject(id) {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.delete(`${URL}/${id}`, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

const projectAPI = {
  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  getPublicProjects,
};

export { projectAPI };
