<template>
  <v-row id="heading-5" class="pb-15 secondary">
    <v-col cols="12" align="center" class="pt-15 px-5 px-md-0">
      <h1
        class="pt-15 primary--text title-font text-lg-h3 text-md-h3"
        data-aos="fade-down"
        data-aos-delay="1200"
        data-aos-duration="1500"
      >
        {{ $t("lblNuestrosProductos") }}
      </h1>
      <h4
        class="font-weight-medium support--text body-font pt-8 pb-16 text-lg-h6 px-md-16"
        data-aos="fade-up"
        data-aos-delay="1500"
        data-aos-duration="1500"
      >
        {{ $t("lblConozcaNuestroProductos") }}
      </h4>
    </v-col>
    <principal-preview v-for="item of items" :item="item" :key="item.id" />
    <!-- <default-view :items="previewProyectItems" v-if="previewProyectItems" /> -->
    <!-- <v-col
      cols="12"
      md="6"
      lg="4"
      class="offset-lg-4 offset-md-3 py-15 d-flex justify-center"
    >
      <v-btn
        outlined
        :x-large="$vuetify.breakpoint.mdAndUp"
        :block="$vuetify.breakpoint.mdAndUp"
        color="primary"
        class="pa-10"
        @click="gotoProyectos"
        data-aos="fade-up"
        data-aos-delay="2500"
        data-aos-duration="1500"
      >
        {{ $t("btnVerProductos") }}
      </v-btn>
    </v-col> -->
  </v-row>
</template>

<script>
import PrincipalPreview from "@/components/proyects/PrincipalPreview";
// import DefaultView from "@/components/proyects/DefaultView";
import { mapState } from "vuex";

export default {
  name: "PreviewProyects",
  components: { PrincipalPreview },
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      items: (state) => state.projects.publicProjects,
      loading: (state) => state.projects.loading,
    }),
    firstProyect() {
      return this.items[0];
    },
    previewProyectItems() {
      return this.items.slice(1, 3);
    },
  },
  methods: {
    gotoProyectos() {
      this.$router.push({ name: "Productos" });
    },
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped></style>
