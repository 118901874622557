import Vue from "vue";

function success(message) {
  Vue.$toast.success(message, {
    duration: 5000,
    position: "top-right",
  });
}

function warning(message) {
  Vue.$toast.warning(message, {
    duration: 5000,
    position: "top-right",
  });
}

function error(message) {
  Vue.$toast.error(message, {
    duration: 5000,
    position: "top-right",
  });
}

function info(message) {
  Vue.$toast.info(message, {
    duration: 5000,
    position: "top-right",
  });
}

const messages = {
  success,
  warning,
  error,
  info,
};

export { messages };
